import React from 'react'
import s from 'styled-components'
import queryString from 'query-string'

import Layout from '../components/layout'
import SEO from '../components/seo'

const Box = s.div`
  background: #E8E8E8;
  text-align:center;
  padding:10%;
`
const H1 = s.h1`
  color:#c14c40;
`
const H3 = s.h3`
  color:#c14c40;
`

const ErrorView = props => {
  const values = queryString.parse(props.location.search)
  return (
    <Layout>
      <SEO keywords={[`invitree`]} />
      <Box>
        <H1>Error! {values.error}</H1>
        <H3>{values.error_description}</H3>
      </Box>
    </Layout>
  )
}

export default ErrorView
